import React from 'react'
import styled from 'styled-components'

import { Text } from 'components/shared/typography'
import LazyImage from 'components/shared/lazyImage'
import Icon from 'components/shared/icon'

import arrowRightIcon from 'assets/icons/arrow-right-white.svg'

import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

type GalleryItem = {
  img: ImageDataLike
  alt: string
  label: string
  link: string
}

type Props = {
  gallery: GalleryItem[]
}

const GalleryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.media.lg.min} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    grid-column-gap: 5px;
    grid-row-gap: 5px;

    overflow: hidden;
    border-radius: 50px;
  }
`

const ImgWrapper = styled.div`
  ${({ theme }) => theme.media.lg.min} {
    width: 100%;
    & > div {
      width: 100%;
    }
  }
`

const ImgInfo = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  max-width: 340px;
  padding: 10px 20px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.primary100};
`

const GalleryItemWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    ${ImgInfo} {
      background-color: ${({ theme }) => theme.colors.primary300};
    }
  }

  ${({ theme }) => theme.media.lg.min} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;

    ${ImgInfo} {
      background-color: ${({ theme }) => theme.colors.primary};
    }

    &:hover {
      ${ImgInfo} {
        background-color: ${({ theme }) => theme.colors.primary100};
      }
    }

    :nth-child(1) {
      grid-area: 1 / 1 / 2 / 4;
      ${ImgWrapper} {
        // Gatsby image wrappper
        & > div {
          height: 340px;
        }
      }
    }

    :nth-child(2) {
      grid-area: 2 / 1 / 3 / 2;
    }

    :nth-child(3) {
      grid-area: 2 / 2 / 3 / 3;
    }

    :nth-child(4) {
      grid-area: 2 / 3 / 3 / 4;
    }

    :not(:first-child) {
      ${ImgWrapper} {
        // Gatsby image wrappper
        & > div {
          height: 150px;
        }
      }

      ${ImgInfo} {
        position: unset;
        transform: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        max-width: unset;
        height: 85px;
        padding: 10px 20px 10px 30px;
        border-radius: 0;

        ${Text} {
          max-width: 85%;
          font-size: 14px;
          text-align: left;
        }
      }
    }
  }

  ${({ theme }) => theme.media.xxl.min} {
    :nth-child(1) {
      ${ImgWrapper} {
        // Gatsby image wrappper
        & > div {
          height: 380px;
        }
      }
    }

    :not(:first-child) {
      ${ImgWrapper} {
        // Gatsby image wrappper
        & > div {
          height: 165px;
        }
      }

      ${ImgInfo} {
        ${Text} {
          font-size: 16px;
        }
      }
    }
  }
`

const TilesGallery2: React.FC<Props> = ({ gallery }) => {
  const { lg } = useBreakpoint()

  return (
    <GalleryWrapper>
      {gallery.map(({ img, alt, label, link }, index) => (
        <GalleryItemWrapper as="a" href={link} key={`${label}-${index}`}>
          <ImgWrapper>
            <LazyImage src={img} alt={alt} />
          </ImgWrapper>
          <ImgInfo>
            <Text
              size={lg ? 18 : 16}
              weight={500}
              themecolor="white"
              align={'center'}
              dangerouslySetInnerHTML={{ __html: label }}
            />
            {lg && index !== 0 && (
              <Icon src={arrowRightIcon} alt="arrow-right" />
            )}
          </ImgInfo>
        </GalleryItemWrapper>
      ))}
    </GalleryWrapper>
  )
}

export default TilesGallery2
