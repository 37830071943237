import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'
import Icon from 'components/shared/icon'

import background from 'assets/images/about-img.png'
import arrowRightIcon from 'assets/icons/arrow-right-white.svg'

import LazyLoad from 'react-lazyload'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  heading: string
  description: string
  descriptionTitle: string
}

const Section = styled.section`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.media.lg.min} {
    height: 650px;
    padding-top: 25px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: unset;
  }
`

const InnerWrapper = styled.div`
  min-height: 75vh;
  background-image: url('${background}');
  background-position: 40% 50%;
  background-repeat: no-repeat;

  ${({ theme }) => theme.media.lg.min} {
    min-height: unset;
    height: 100%;
    overflow: hidden;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    background-size: cover;
  }

  ${({ theme }) => theme.media.xxl.min} {
    height: 660px;
    border-bottom-left-radius: 80px;
    border-bottom-right-radius: 80px;
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  ${({ theme }) => theme.media.xs.min} {
    padding-top: 50px;
  }

  ${({ theme }) => theme.media.lg.min} {
    align-items: flex-start;
    max-width: 400px;
    padding-top: 75px;
    padding-bottom: 0;
  }

  ${({ theme }) => theme.media.xxl.min} {
    max-width: 550px;
  }
`

const TextLink = styled.a``

const ArrowIconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 157px;
  transform: translateY(-50%);

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ theme }) => theme.media.lg.min} {
    left: 155px;
  }

  ${({ theme }) => theme.media.xxl.min} {
    left: 185px;
  }
`

const About: React.FC<Props> = ({ heading, descriptionTitle, description }) => {
  const { lg, xxxl } = useBreakpoint()

  return (
    <Section title={heading ? heading.replace(/<br\/> /g, '') : ''}>
      <LazyLoad
        offset={500}
        style={{
          width: '100%',
          height: '100%',
        }}
        once
      >
        {lg && (
          <Heading
            as="h2"
            size={28}
            weight={600}
            align="center"
            themecolor="primary"
            margin="30px"
            dangerouslySetInnerHTML={{ __html: heading }}
          />
        )}
        <InnerWrapper>
          <Container>
            <TextContent>
              {!lg && (
                <Heading
                  as="h2"
                  size={28}
                  weight={600}
                  align="center"
                  themecolor="white"
                  margin="30px"
                  dangerouslySetInnerHTML={{ __html: heading }}
                />
              )}
              <Heading
                size={xxxl ? 34 : 32}
                as="h3"
                weight={400}
                align={lg ? 'left' : 'center'}
                themecolor="white"
                margin="20px"
                dangerouslySetInnerHTML={{ __html: descriptionTitle }}
              />
              <TextLink href="/o-firmie/">
                <Text
                  weight={500}
                  size={xxxl ? 18 : 16}
                  line={xxxl ? 1.6 : 1.4}
                  themecolor="white"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <Text
                  weight={800}
                  align="left"
                  size={xxxl ? 18 : 16}
                  line={xxxl ? 1.6 : 1.4}
                  themecolor="white"
                  style={{ alignSelf: 'flex-start' }}
                >
                  Zawsze na froncie.
                  <ArrowIconWrapper>
                    <Icon src={arrowRightIcon} size={13} alt="arrow-down" />
                  </ArrowIconWrapper>
                </Text>
              </TextLink>
            </TextContent>
          </Container>
        </InnerWrapper>
      </LazyLoad>
    </Section>
  )
}

export default About
