import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import Solutions from 'components/layout/Sections/Solutions'
import Steps from 'components/layout/Sections/Steps'
import Products from 'components/layout/Sections/Products'
import VideoSection from 'components/layout/Sections/Video'
import About from 'components/layout/Sections/About'
import Activity from 'components/layout/Sections/SocialActivity'

import Navigation from 'components/layout/Navigation/index'

// const breadcrumbs = {
//   mobile: [
//     {
//       label: 'Home',
//       link: '/',
//     },
//     {
//       label: 'Dla przemysłu',
//     },
//   ],
//   desktop: [
//     {
//       label: 'Home',
//       link: '/',
//     },
//     {
//       label: 'Dla przemysłu',
//     },
//   ],
// }

const aboutDescriptionTitle = 'Zawsze <br/> na froncie'

const activityHeading = 'Działalność <br/> społeczna'

const DlaPrzemysluPage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.dlaPrzemyslu
  const PAGE_SEO = data?.wpPage?.seo

  const HERO_SLIDES = React.useMemo(
    () =>
      PAGE?.forIndustriesBannerHero?.map((item) => ({
        src: item.bannerHeroImg.localFile.childImageSharp.gatsbyImageData,
        alt: 'hero',
        heading: item.bannerHeroText,
      })),
    []
  )

  const SOLUTIONS_GALLERY = [
    {
      img: PAGE.forIndustriesTilesFence.tilesFenceImg.localFile.childImageSharp
        .gatsbyImageData,
      alt: PAGE.forIndustriesTilesFence.tilesFenceImg.altText,
      link: '/ogrodzenia/',
      title: PAGE.forIndustriesTilesFence.tilesFenceText,
    },
    {
      img: PAGE.forIndustriesTilesGates.tilesGatesImg.localFile.childImageSharp
        .gatsbyImageData,
      alt: PAGE.forIndustriesTilesGates.tilesGatesImg.altText,
      link: '/bramy-wjazdowe/',
      title: PAGE.forIndustriesTilesGates.tilesGatesText,
    },
    {
      img: PAGE.forIndustriesTilesForEstates.tilesForEstatesImg.localFile
        .childImageSharp.gatsbyImageData,
      alt: PAGE.forIndustriesTilesForEstates.tilesForEstatesImg.altText,
      link: '/',
      title: PAGE.forIndustriesTilesForEstates.tilesForEstatesText,
    },
    {
      img: PAGE.forIndustriesTilesTeam.tilesTeamImg.localFile.childImageSharp
        .gatsbyImageData,
      alt: PAGE.forIndustriesTilesTeam.tilesTeamImg.altText,
      link: '/mala-architektura/',
      title: PAGE.forIndustriesTilesTeam.tilesTeamText,
    },
  ]

  const PRODUCTS = React.useMemo(
    () =>
      PAGE?.forIndustriesProductsCollection?.map((item) => ({
        img: item.productsCollectionImg.localFile.childImageSharp
          .gatsbyImageData,
        label: item.productsCollectionCollectionName,
        link: item.productsCollectionCollectionLink,
        alt: item.productsCollectionImg.altText,
      })),
    []
  )

  const POSTS_GALLERY =
    React.useMemo(() => {
      const newestNewsPost = {
        img:
          data?.newestNewsPost.nodes[0].artykul?.articleArticleImg?.localFile
            ?.childImageSharp?.gatsbyImageData! ||
          data?.newestNewsPost.nodes[0].artykul?.articleHeroImg?.localFile
            ?.childImageSharp?.gatsbyImageData!,
        alt: 'article',
        label: data?.newestNewsPost.nodes[0].title,
        link: `/wiadomosci/aktualnosci/${data?.newestNewsPost.nodes[0].slug}/`,
      }

      const newestTrendsPosts = data?.newestTrendsPosts?.nodes.map((item) => ({
        img:
          item?.artykul?.articleArticleImg?.localFile?.childImageSharp
            ?.gatsbyImageData! ||
          item?.artykul?.articleHeroImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
        alt: 'article',
        label: item?.title,
        link: `/wiadomosci/porady-i-trendy/${item?.slug}/`,
      }))

      return [newestNewsPost, ...newestTrendsPosts]
    }, []) ?? []

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={data.heroImgMobile.childImageSharp.gatsbyImageData}
        slides={HERO_SLIDES}
        buttonLabel={PAGE.forIndustriesBannerButton}
        // breadcrumbs={breadcrumbs}
      />
      <Solutions
        heading={PAGE.forIndustriesTilesTitle}
        solutions={SOLUTIONS_GALLERY}
        evenColumns
        backgroundMobile="primary100"
        backgroundDesktop="gray"
      />
      <Steps
        heading={PAGE.forIndustriesLeadTimeTitle}
        steps={PAGE.forIndustriesLeadTimeAdvantages}
      />
      <Products heading={PAGE.forIndustriesProductsTitle} products={PRODUCTS} />
      <VideoSection
        heading={PAGE.forIndustriesInnovationTitle}
        videoUrl={PAGE.forIndustriesInnovationFilm}
      />
      <About
        heading={PAGE.forIndustriesAboutCompanyTitle}
        descriptionTitle={aboutDescriptionTitle}
        description={PAGE.forIndustriesAboutCompanyText}
      />
      <Activity heading={activityHeading} postsGallery={POSTS_GALLERY} />
    </Layout>
  )
}

export default DlaPrzemysluPage

export const query = graphql`
  query DlaPrzemysluPage {
    heroImgMobile: file(name: { eq: "hero-img-mobile-2" }) {
      childImageSharp {
        gatsbyImageData(
          width: 992
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
        )
      }
    }
    postImg: file(name: { eq: "post-img-1" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
        )
      }
    }
    wpPage(slug: { regex: "/dla-przemyslu/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      dlaPrzemyslu {
        forIndustriesBannerHero {
          bannerHeroText
          bannerHeroImg {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        forIndustriesBannerButton
        forIndustriesTilesTitle
        forIndustriesTilesFence {
          tilesFenceText
          tilesFenceImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        forIndustriesTilesGates {
          tilesGatesText
          tilesGatesImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        forIndustriesTilesForEstates {
          tilesForEstatesText
          tilesForEstatesImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        forIndustriesTilesTeam {
          tilesTeamText
          tilesTeamImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        forIndustriesLeadTimeTitle
        forIndustriesLeadTimeAdvantages {
          leadTimeAdvantagesText
          leadTimeAdvantagesIcon
        }
        forIndustriesProductsTitle
        forIndustriesProductsCollection {
          productsCollectionCollectionName
          productsCollectionCollectionLink
          productsCollectionImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        forIndustriesInnovationTitle
        forIndustriesInnovationFilm
        forIndustriesAboutCompanyText
        forIndustriesAboutCompanyTitle
      }
    }
    newestNewsPost: allWpAcrtile(
      filter: { artykul: { articleArticleTyp: { eq: "news" } } }
      sort: { fields: artykul___articleArticleImg___date, order: DESC }
      limit: 1
    ) {
      nodes {
        artykul {
          articleArticleTyp
          articleArticleImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1650
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          articleHeroImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1650
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        slug
        title
      }
    }
    newestTrendsPosts: allWpAcrtile(
      filter: { artykul: { articleArticleTyp: { eq: "trends" } } }
      sort: { fields: artykul___articleArticleImg___date, order: DESC }
      limit: 3
    ) {
      nodes {
        artykul {
          articleArticleTyp
          articleArticleImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1650
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
          articleHeroImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 1650
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
        slug
        title
      }
    }
  }
`
