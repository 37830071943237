import React from 'react'
import styled from 'styled-components'

import TilesGallery2 from 'components/layout/TilesGallery/TilesGallery2'

import Container from 'components/shared/container'
import { Heading } from 'components/shared/typography'

import LazyLoad from 'react-lazyload'

import { ImageDataLike } from 'gatsby-plugin-image'

import useBreakpoint from 'hooks/useBreakpoint'

type Post = {
  img: ImageDataLike
  alt: string
  label: string
  link: string
}

type Props = {
  heading: string
  postsGallery: Post[]
}

const Section = styled.section`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 40px 0;

  ${({ theme }) => theme.media.s.min} {
    ${Heading} {
      br {
        display: none;
      }
    }
  }

  ${({ theme }) => theme.media.lg.min} {
    padding: 20px 0 60px;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const StyledContainer = styled(Container)`
  padding: 0;
  max-width: 768px;

  ${Heading} {
    width: fit-content;
    margin: 0 auto 20px auto;
  }

  ${({ theme }) => theme.media.lg.min} {
    max-width: ${({ theme }) =>
      `calc(${theme.container.width} + (${theme.container.padding} * 2))`};
    padding: 20px ${({ theme }) => theme.container.paddingMD} 0;
  }

  ${({ theme }) => theme.media.xxl.min} {
    max-width: ${({ theme }) =>
      `calc(${theme.container.widthXL} + (${theme.container.padding} * 2))`};
  }
`

const Activity: React.FC<Props> = ({ heading, postsGallery }) => {
  const { lg } = useBreakpoint()

  return (
    <Section title={heading ? heading.replace(/<br\/> /g, '') : ''}>
      <StyledContainer>
        <Heading
          as="a"
          href="/wiadomosci/"
          size={28}
          weight={600}
          align="center"
          themecolor={lg ? 'primary' : 'white'}
          margin="0"
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <LazyLoad
          offset={500}
          height="100%"
          style={{
            width: '100%',
          }}
          once
        >
          <TilesGallery2 gallery={postsGallery} />
        </LazyLoad>
      </StyledContainer>
    </Section>
  )
}

export default Activity
